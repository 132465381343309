import * as v from 'valibot';
import form from '~/components/valibot/form';
import { TimeZone } from '~/lib/enum';

export const SCHEMA_API_SCHEDULE_ROUTE = v.object({
  sunset: form.optional(form.datetime()),
  start: form.isoDate(),
  end: form.isoDate(),
  blocked: v.record(v.string(), v.array(v.string())),
  providers: v.array(
    v.object({
      id: v.string(),
      name: v.string(),
      busy: v.array(
        v.object({
          start: form.datetime(),
          end: form.datetime(),
        }),
      ),
    }),
  ),
  appointments: v.array(
    v.object({
      id: v.string(),
      address: v.string(),
      latitude: v.number(),
      longitude: v.number(),
      timezone: v.enum(TimeZone),
      windows: v.array(
        v.object({
          start: form.datetime(),
          end: form.datetime(),
        }),
      ),
      providers: v.array(
        v.object({
          id: v.string(),
          name: v.string(),
          priority: v.nullable(v.number()),
          duration: v.number(),
          expense: form.money({ positive: true }),
        }),
      ),
      sunset: form.boolean,
    }),
  ),
});

export const SCHEMA_API_DOMAIN_AVAILABLE = v.object({
  domain: v.pipe(
    v.string('Please enter a domain name.'),
    v.endsWith('.com', 'Only domains that end in .com are supported.'),
  ),
});
